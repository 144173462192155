/* eslint no-return-assign: 0 */

import React, { Fragment } from 'react';
import { array, bool, func, number, object, oneOfType, string } from 'prop-types';
import styled from 'styled-components';
import { blankish } from 'libs/support/string';
import {
  InputField,
  SelectField,
  ToggleField,
} from 'libs/components/Forms';
import {
  DeleteButton,
} from 'bundles/common/components/Buttons';
import {
  DEFAULT_SERVING_DESCRIPTIONS,
  PANTRY_SERVING_DESCRIPTIONS,
  ACTIVITIES_PRICE_DESCRIPTIONS,
} from 'bundles/vendor/constants/menuItemsConstants';
import { useSelectInput } from 'libs/support/selectInput';
import { usePrice } from 'libs/support/usePrice';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';

const Row = styled.tr`
`;

const Cell = styled.td`
  padding: 0.75rem 0.3rem;
`;

const InvisibleButton = styled.button`
  display: none;
`;

const TextPrice = styled.div.attrs({
  className: 'text-price',
})`
  font-size: 0.9em;
  text-align: right;
`;

const Variation = ({
  deleteVariation,
  variation,
  isDeletable,
  isNameMandatory,
  index,
  markupPricePercent,
  taxRate,
  taxType,
  vendorKind,
  measurementUnits,
  roundingAdjustment,
}) => {
  const handleDelete = () => {
    deleteVariation(variation.get('uuid') || variation.get('tempId'));
  };

  const basePrice = variation.get('base_price');
  const [
    vendorPrice,
    displayPrice,
    inputPriceRef,
    displayPriceRef,
    handleVendorPriceChange,
    handleVendorPriceBlur,
  ] = usePrice({ basePrice, taxType, taxRate, markupPricePercent, roundingAdjustment });

  const descriptionsList = () => {
    switch (vendorKind) {
      case 'activities':
        return ACTIVITIES_PRICE_DESCRIPTIONS;
      case 'pantry':
        return PANTRY_SERVING_DESCRIPTIONS;
      default:
        return DEFAULT_SERVING_DESCRIPTIONS;
    }
  };

  const servingsDescriptionOptions = descriptionsList().map(value => (
    { label: value, value }
  ));

  const [selectedServingsDescriptionOption, handleServingsDescriptionChange] = useSelectInput(
    servingsDescriptionOptions,
    variation.get('servings_description'),
  );

  const uomOptions = measurementUnits.map((uom) => ({
    label: uom.get('name'),
    value: uom.get('name'),
  }));

  const [selectedUom, handleSelectedUomChange] = useSelectInput(
    uomOptions,
    variation.get('unit_of_measurement'),
  );

  const isActivities = vendorKind === 'activities';
  const isGifts = vendorKind === 'gifts';
  const isPantry = vendorKind === 'pantry';

  const style = { display: blankish(variation.get('deleted_at')) ? 'table-row' : 'none' };

  return (
    <Row style={style}>
      <Cell>
        <InputField
          id={`menu_item_variations_attributes_${index}_id`}
          name="menu_item[variations_attributes][][id]"
          value={variation.get('uuid')}
          type="hidden"
        />
        <InputField
          id={`menu_item_variations_attributes_${index}_deleted_at`}
          name="menu_item[variations_attributes][][deleted_at]"
          value={variation.get('deleted_at')}
          type="hidden"
        />
      </Cell>
      <Cell>
        <InputField
          id={`menu_item_variations_attributes_${index}_name`}
          name="menu_item[variations_attributes][][name]"
          value={variation.get('name')}
          isRequired={isNameMandatory}
        />
      </Cell>
      <Cell>
        <InputField
          id={`menu_item_variations_attributes_${index}_more_info`}
          name="menu_item[variations_attributes][][more_info]"
          value={variation.get('more_info')}
        />
      </Cell>
      <Cell>
        <InputField
          id={`menu_item_variations_attributes_${index}_vendor_price`}
          name="menu_item[variations_attributes][][vendor_price]"
          placeholder="0.00"
          value={vendorPrice}
          onChange={handleVendorPriceChange}
          onBlur={handleVendorPriceBlur}
          type="number"
          pattern="^\d*(\.\d{0,2})?$"
          step="0.01"
          rightAligned
          isRequired
        />
      </Cell>
      <Cell>
        <input
          id={`menu_item_variations_attributes_${index}_price`}
          name="menu_item[variations_attributes][][price]"
          ref={inputPriceRef}
          defaultValue={basePrice}
          style={{ height: 0, width: 0, position: 'absolute', border: 'none', visibility: 'hidden' }}
        />
        <TextPrice
          id={`menu_item_variations_attributes_${index}_display_price`}
          ref={displayPriceRef}
        >
          { displayPrice }
        </TextPrice>
      </Cell>
      <Cell>
        <InputField
          id={`menu_item_variations_attributes_${index}_min_order_quantity`}
          name="menu_item[variations_attributes][][min_order_quantity]"
          value={variation.get('min_order_quantity') ?? null}
          type="number"
          step={1}
          rightAligned
          isRequired
        />
      </Cell>
      {
        !isGifts && !isPantry &&
          <Cell>
            <SelectField
              id={`menu_item_variations_attributes_${index}_servings_description`}
              name="menu_item[variations_attributes][][servings_description]"
              value={[selectedServingsDescriptionOption]}
              options={servingsDescriptionOptions}
              onChange={handleServingsDescriptionChange}
              hideIndicator
              menuPortalTarget={document.querySelector('body')}
              isRequired
            />
          </Cell>
      }
      {
        !isActivities && !isGifts && !isPantry &&
          <Fragment>
            <Cell>
              <InputField
                id={`menu_item_variations_attributes_${index}_servings`}
                name="menu_item[variations_attributes][][servings]"
                value={variation.get('servings')}
                type="text"
                hideStepper
                rightAligned
                isRequired
              />
            </Cell>
            <Cell>
              <ToggleField
                id={`menu_item_variations_attributes_${index}_display_serving`}
                name="menu_item[variations_attributes][][display_serving]"
                defaultChecked={variation.get('display_serving')}
                isRequired
              />
            </Cell>
          </Fragment>
      }
      {
        isPantry && (
          <Fragment>
            <Cell>
              <InputField
                id={`menu_item_variations_attributes_${index}_pack_size`}
                name="menu_item[variations_attributes][][pack_size]"
                value={variation.get('pack_size')}
                type="number"
                step={0.01}
                rightAligned
                isRequired
              />
            </Cell>
            <Cell>
              <SelectField
                id={`menu_item_variations_attributes_${index}_unit_of_measurement`}
                name="menu_item[variations_attributes][][unit_of_measurement]"
                value={[selectedUom]}
                options={uomOptions}
                onChange={handleSelectedUomChange}
                hideIndicator
                menuPortalTarget={document.querySelector('body')}
                isRequired
              />
            </Cell>
            <Cell>
              <InputField
                id={`menu_item_variations_attributes_${index}_units`}
                name="menu_item[variations_attributes][][units]"
                value={variation.get('units')}
                type="number"
                step={1}
                rightAligned
                isRequired
              />
            </Cell>
          </Fragment>
        )
      }
      <Cell>
        <InvisibleButton />
        { isDeletable && <DeleteButton className="delete-button" onClick={handleDelete} /> }
      </Cell>
    </Row>
  );
};

Variation.propTypes = {
  deleteVariation: func.isRequired,
  isDeletable: bool,
  isNameMandatory: bool,
  index: number.isRequired,
  taxRate: oneOfType([string, number]).isRequired,
  taxType: string.isRequired,
  markupPricePercent: string.isRequired,
  variation: object.isRequired,
  vendorKind: string.isRequired,
  measurementUnits: array.isRequired,
  roundingAdjustment: number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteVariation: menuItemsActions.deleteVariation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Variation);
