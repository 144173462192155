import React from 'react';
import PropTypes from 'prop-types';
import { ORDER_ACTIONS } from 'bundles/v2/orders/constants/orderConstants';

const OrderNoticeCancellation = ({
  isLoadingAction,
  onChangeAction,
  translate,
  isCancelOrderSuccess,
}) => (
  <div className="notice notice-purple notice-purple-changes mb-1">
    {
      isLoadingAction ?
        <div className="loader-container"><span className="loader" /></div> :
        <>
          {
            isCancelOrderSuccess ?
              <>
                <span className="notice-text-header notice-text-header-success">
                  {translate('ordersShowDetailsOrderNoticeCancellationRequestNoticeCancelled')}
                </span>
              </> :
              <>
                <div className="notice-text-group">
                  <span className="notice-text-header">
                    {translate('ordersShowDetailsOrderNoticeCancellationRequested')}
                  </span>
                  <span className="notice-text-subheader mt-8">
                    {translate('ordersShowDetailsOrderNoticeCancellationRequestedDescription')}
                  </span>
                </div>
                <div className="notice-button-group notice-button-group-change-requested">
                  <button
                    className="btn btn-accept"
                    onClick={(e) => onChangeAction(e, ORDER_ACTIONS.cancel_order)}
                  >
                    {translate('ordersShowDetailsOrderNoticeCancellationRequestNoticeAcknowledge')}
                  </button>
                </div>
              </>
          }

        </>
    }
  </div>
);

OrderNoticeCancellation.propTypes = {
  isLoadingAction: PropTypes.bool.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isCancelOrderSuccess: PropTypes.bool.isRequired,
};

export default OrderNoticeCancellation;
