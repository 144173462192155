/* eslint-disable no-alert */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useState } from 'react';
import Immutable from 'immutable';
import ChevronRight from 'libs/components/Icons/v2/ChevronRight';
import LargeNote from 'libs/components/Icons/v2/LargeNote';
import LargeTruck from 'libs/components/Icons/v2/LargeTruck';
import LargeCart from 'libs/components/Icons/v2/LargeCart';
import ArrowLeft from 'libs/components/Icons/v2/ArrowLeft';
import moment from 'moment';
import { array, string, bool, func, object } from 'prop-types';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';
import MobileHeader from 'bundles/v2/common/components/MobileHeader';

import { Flash } from 'libs/components/Forms';
import { useUpdateOrderDriver } from 'bundles/v2/orders/queries/orders';

import BillingDetails from './BillingDetails';
import OrderHistory from './OrderHistory';
import OrderCarts from './OrderCarts';
import MobileOrderCarts from './MobileOrderCarts';
import OrderNotice from './OrderNotice';
import PrintSection from './PrintSection';
import DriverForm from './DriverForm';
import driverParamsBuilder from '../../utils/driverParamsBuilder';

const DesktopOrderDetails = ({
  translate,
  isDraftOrder,
  orderable,
  orderableType,
  showMoreBilling,
  setShowMoreBilling,
  showMoreHistory,
  setShowMoreHistory,
  orderBilling,
  errorMsg,
  onChangeAction,
  isLoadingAction,
  isAcceptOrderSuccess,
  isDeclineOrderSuccess,
  showDeclineModal,
  showAcceptModal,
  isAcceptChangesOrderSuccess,
  isDeclineChangesOrderSuccess,
  isConfirmOrderReminderSuccess,
  orderHistories,
  vendorDrivers,
  selectedDriver,
  isCancelOrderSuccess,
  flagIsEnabled,
}) => {
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [selectedOrderHistory, setSelectedOrderHistory] = useState({});
  const [errors, setErrors] = useState('');
  const deliveryContactEnabled = flagIsEnabled('delivery_contact_enabled');
  const [flash, setFlash] = useState(Immutable.Map());

  const { address, user, vendor: { outlets } } = orderable;
  const outlet = outlets.find((o) => o.uuid === orderable.outletId);
  const orderableVariations = orderable[`consolidated${uppercamelcase(orderableType)}Variations`];
  const totalQuantityOrderVariation =
    orderableVariations?.reduce((total, orderVariation) => total + orderVariation.quantity, 0) || 0;
  const totalQuantityCustomMenuItem =
    orderable.customMenuItems?.reduce((total, customMenuItem) => total + customMenuItem.quantity, 0) || 0;
  const totalQuantity = totalQuantityOrderVariation + totalQuantityCustomMenuItem;
  const customMenuItems = orderable?.customMenuItems;
  const onClickSetHistory = (e, orderHistory) => {
    e.preventDefault();
    setSelectedOrderHistory(orderHistory);
    setOpenModalHistory(true);
  };

  const onClickChangeRequestDetails = (e) => {
    e.preventDefault();
    const sortedByDateOrderHistories = orderHistories.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    onClickSetHistory(e, sortedByDateOrderHistories[0]);
  };

  const {
    mutateAsync: updateOrderDriver,
  } = useUpdateOrderDriver(orderable.uuid);

  const onClickSaveVendorDriver = () => {
    const isValidSingaporeNumber = (number) => {
      const pattern = /^[89]\d{7}$/;
      return pattern.test(number);
    };

    const form = document.getElementById('driver_form');
    const formData = new FormData(form);

    const orderDriverNameOrId = formData.get('order_driver_id'); // driverId or driverName(new driver)
    const driverPhoneNumber = formData.get('order_driver_phone');
    const payload = driverParamsBuilder(orderDriverNameOrId, driverPhoneNumber, vendorDrivers);

    if (!payload.driverId && !payload.driverName) {
      setErrors('Please select driver');
      return;
    }
    if (driverPhoneNumber === '' || !isValidSingaporeNumber(driverPhoneNumber)) {
      setErrors('Please enter a valid Singapore mobile number');
      return;
    }

    updateOrderDriver(payload).then(() => {
      setFlash(Immutable.fromJS({ notice: translate('ordersShowFormsDriverUpdateSuccess') }));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  return (
    <div>
      <div className="order-details__header">
        <a className="order-text" href="/orders">{translate('ordersShowDetailsOrders')}</a>
        <ChevronRight />
        <span className="order-code">{orderable.code}</span>
      </div>
      <MobileHeader>
        <div className="d-flex mobile-order-header-titles">
          <span onClick={() => window.location = '/orders'}>
            <ArrowLeft />
          </span>
          <span className="order-text">Order: {orderable.code}</span>
          { !['newly_placed', 'new_changes_requested', 'changes_requested'].includes(orderable.status) ?
            <PrintSection
              orderable={orderable}
              mobile
            />
          :
            <span />
          }
        </div>
      </MobileHeader>
      <div className="order-details__body">
        <OrderNotice
          {
            ...{
              orderable,
              orderableType,
              errorMsg,
              onChangeAction,
              isLoadingAction,
              isAcceptOrderSuccess,
              isDeclineOrderSuccess,
              showDeclineModal,
              showAcceptModal,
              isAcceptChangesOrderSuccess,
              isDeclineChangesOrderSuccess,
              isConfirmOrderReminderSuccess,
              onClickChangeRequestDetails,
              orderHistories,
              isCancelOrderSuccess,
            }
          }
        />
        { !['newly_placed', 'new_changes_requested', 'changes_requested'].includes(orderable.status) &&
          <PrintSection orderable={orderable} />
        }
        <div className={`order-details-twin-box ${orderable.status}`}>
          <div className={`order-details-box ${orderable.status}`}>
            <div className="order-details-group-header">
              <LargeNote />
              <span className="order-details-text-header">{translate('ordersShowDetailsOrderDetails')}</span>
            </div>
            <div className="row mt-2">
              <div className="col-xs-6">
                <div className="order-details-group">
                  <span className="order-details-title">{translate('ordersShowDetailsDeliveryDate')}</span>
                  <span className="order-details-value">{ moment(orderable.deliverAt).utc().format('ddd D MMM YYYY') }</span>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="order-details-group">
                  <span className="order-details-title">{translate('ordersShowDetailsDeliveryTime')}</span>
                  <span className="order-details-value">{ moment(orderable.deliverAt).utc().format('h:mm A') }</span>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-xs-6">
                <div className="order-details-group">
                  <span className="order-details-title">{translate('ordersShowDetailsOrderId')}</span>
                  <span className="order-details-value">{ orderable.code }</span>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="order-details-group">
                  <span className="order-details-title">{translate('ordersShowDetailsStatus')}</span>
                  <span>
                    <span
                      className={`chip ${orderable.status}`}
                    >
                      {translate(`ordersStates${uppercamelcase(orderable.status)}`)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-xs-12">
                <div className="order-details-group">
                  <span className="order-details-title">{translate('ordersShowDetailsOutlet')}</span>
                  <span className="order-details-value order-details-address">
                    <span>{ outlet?.name }</span>
                    <span>{ outlet?.unitBuilding }</span>
                    <span>{ outlet?.streetAddress }, {outlet?.postcode} , {outlet?.city}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="order-details-delivery-box">
            <Flash flash={flash} />
            <div className="order-details-group-header">
              { orderable.deliveryType === 'own' ? <LargeTruck /> : <LargeCart /> }
              <span className="order-details-text-header">
                {translate(`ordersShowDetailsDeliveryTypes${uppercamelcase(orderable.deliveryType)}`)}
              </span>
            </div>
            { orderable.deliveryType === 'own' ?
              <Fragment>
                {
                  window.fflags && window.fflags.vendor_delivery_qr_enabled &&
                    <DriverForm
                      {...{
                        onClickSaveVendorDriver,
                        vendorDrivers,
                        translate,
                        selectedDriver,
                        errors,
                      }}
                    />
                }
                <div className="row mt-2">
                  <div className="col-xs-6">
                    <div className="order-details-group">
                      <span className="order-details-title">{translate('ordersShowDetailsAddress')}</span>
                      <span className="order-details-value order-details-address">
                        <span>{ address.unitAddress }</span>
                        <span>{ address.streetAddress }, {address.postcode} , {address.cityFullName}</span>
                      </span>
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div className="order-details-group">
                      <span className="order-details-title">{translate('ordersShowDetailsCompany')}</span>
                      <span className="order-details-value">{user.company}</span>
                    </div>
                  </div>
                </div>
                {deliveryContactEnabled ? (
                  <>
                    <div className="row mt-2">
                      <div className="col-xs-6">
                        <div className="order-details-group">
                          <span className="order-details-title">
                            {translate('ordersShowDetailsContactPerson')}
                          </span>
                          <span className="order-details-value">
                            {orderable.vendorResolvedContactFirstName}{' '}
                            {orderable.vendorResolvedContactLastName}
                          </span>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="order-details-group">
                          <span className="order-details-title">
                            {translate('ordersShowDetailsPhone')}
                          </span>
                          <span className="order-details-value">
                            {orderable.vendorResolvedContactMobile}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-2">
                      <div className="col-xs-6">
                        <div className="order-details-group">
                          <span className="order-details-title">
                            {translate('ordersShowDetailsContactPerson')}
                          </span>
                          <span className="order-details-value">
                            {user.firstName} {user.lastName}
                          </span>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="order-details-group">
                          <span className="order-details-title">
                            {translate('ordersShowDetailsPhone')}
                          </span>
                          <span className="order-details-value">
                            {user.mobile}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row mt-2">
                  <div className="col-xs-12">
                    <div className="order-details-group">
                      <span className="order-details-title">{translate('ordersShowDetailsDeliveryInstructions')}</span>
                      <span className="order-details-value order-details-address">
                        <span>{ orderable.additionalInstructions ? orderable.additionalInstructions : '-' }</span>
                      </span>
                    </div>
                  </div>
                </div>
              </Fragment>
              :
              <div className="row mt-2">
                <div className="col-xs-6">
                  <div className="order-details-group">
                    <span className="order-details-title">{translate('ordersShowDetailsDriver')}</span>
                    <span className="order-details-value">
                      <div className="order-details-driver">
                        {orderable?.drivers?.map((driver) => (
                          <span>{driver.name}</span>
                        ))}
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="order-details-group">
                    <span className="order-details-title">{translate('ordersShowDetailsPickupTime')}</span>
                    <span className="order-details-value">
                      { orderable.pickupAt ? moment(orderable.pickupAt).utc().format('h:mm A') : ''}
                    </span>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
        <OrderCarts
          {
            ...{
              order: orderable,
              totalQuantity,
              orderableType,
              orderableVariations,
              customMenuItems,
            }
          }
        />
        <MobileOrderCarts
          {
            ...{
              order: orderable,
              totalQuantity,
              orderableType,
              orderableVariations,
              customMenuItems,
            }
          }
        />
        { !isDraftOrder &&
          <Fragment>
            <BillingDetails
              {
                ...{
                  showMoreBilling,
                  setShowMoreBilling,
                  orderBilling: orderBilling?.orderBilling,
                }
              }
            />
            <OrderHistory
              {
                ...{
                  showMoreHistory,
                  setShowMoreHistory,
                  orderHistories,
                  openModalHistory,
                  setOpenModalHistory,
                  selectedOrderHistory,
                  setSelectedOrderHistory,
                  onClickSetHistory,
                }
              }
            />
          </Fragment>
        }
      </div>
    </div>
  );
};

DesktopOrderDetails.propTypes = {
  translate: func,
  isDraftOrder: bool,
  orderable: object,
  orderableType: string,
  showMoreBilling: bool,
  setShowMoreBilling: func,
  showMoreHistory: bool,
  setShowMoreHistory: func,
  orderBilling: object,
  errorMsg: string,
  onChangeAction: func,
  isLoadingAction: bool,
  isAcceptOrderSuccess: bool,
  isDeclineOrderSuccess: bool,
  showDeclineModal: func,
  showAcceptModal: func,
  isAcceptChangesOrderSuccess: bool,
  isDeclineChangesOrderSuccess: bool,
  isConfirmOrderReminderSuccess: bool,
  orderHistories: array,
  vendorDrivers: array,
  selectedDriver: object,
  isCancelOrderSuccess: bool,
  flagIsEnabled: func,
};

export default withAppContext(withI18n(DesktopOrderDetails));
