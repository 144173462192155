export const FETCHING_ORDERS = 'FETCHING_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const ORDER_ACTIONS = {
  accept: 'accept',
  decline: 'decline',
  decline_changes: 'decline_changes',
  accept_changes: 'accept_changes',
  confirm_order_reminder: 'confirm_order_reminder',
  cancel_order: 'cancel_order',
};
